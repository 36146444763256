/* global fbq */
/* global ttq */

import React, { useState } from 'react';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import awsConfig from '../awsConfig'; // Ensure this path is correct for your project
import Testimonial from '../icons/testimonial.png'; // Ensure this path is correct for your project
import Logo from '../icons/sbedulogo.png'; // Adjust the path as necessary
import { CheckIcon, UserIcon, LightningBoltIcon, AcademicCapIcon } from '@heroicons/react/solid';

const userPool = new CognitoUserPool({
  UserPoolId: awsConfig.UserPoolId,
  ClientId: awsConfig.ClientId,
});

const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const redirectToLemonSqueezyCheckout = async () => {
    // Assuming fbq and ttq are globally available for tracking
    fbq('track', 'InitiateCheckout');

    if (window.ttq) {
      window.ttq.track('AddToCart', {
        contents: [
          {
            content_id: "101",
            content_type: "product",
            content_name: "StudyBot Unlimited",
            quantity: 1 // You can adjust the quantity as needed
          }
        ],
        value: 10,
        currency: "USD"
      });
    }

    try {
      const response = await fetch('https://api.studybot.education/create-lemon-squeezy-checkout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email
        }),
      });
      const session = await response.json();
      if (session.url) {
        window.location.href = session.url;
      } else {
        alert('There was an issue with your request.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Sorry, there was an error. Please try again.');
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    userPool.signUp(email, password, [], null, async (err, result) => {
      if (err) {
        alert(err.message || JSON.stringify(err));
        return;
      }
      await redirectToLemonSqueezyCheckout();
    });
  };

  return (
    <div className="flex flex-col min-h-screen lg:flex-row">
      {/* Sign Up Section - Always visible */}
      <div className="flex-1 flex flex-col justify-center px-6 lg:px-20 xl:px-24 bg-white">
        <div className="w-full max-w-lg">         
          <h3 className="mt-6 text-4xl font-bold text-gray-900">Let's get started with your <span className="bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-purple-800">free trial</span></h3>
          <p className="mt-2 text-sm text-gray-600">
            Already a user? <a href="/login" className="underline text-purple-600">Log in</a>
          </p>
          <div className="mt-8">
            <div className="mt-6">
              <form onSubmit={handleSubmit} className="space-y-6">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                  required
                  className="w-full px-5 py-3 placeholder-gray-400 bg-white border border-gray-300 rounded-md focus:outline-none focus:border-purple-500 focus:ring-1 focus:ring-purple-500 text-black"
                />
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                  required
                  className="w-full px-5 py-3 placeholder-gray-400 bg-white border border-gray-300 rounded-md focus:outline-none focus:border-purple-500 focus:ring-1 focus:ring-purple-500 text-black"
                />
                <button
                  type="submit"
                  className="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                >
                  Create Account
                </button>
              </form>
              <div className="mt-6 text-center">
                <a href="#" className="underline text-sm text-gray-600 hover:text-purple-600">
                  By creating an account you agree to our Terms & Conditions
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Image and Info Section - Hidden on small screens */}
      <div className="hidden lg:flex flex-1 flex-col items-center justify-center bg-gradient-to-r from-purple-700 to-purple-500 p-12">
        <div className="max-w-md w-full text-center">
          <h2 className="text-5xl font-bold text-white mb-8">Your next 'A' is a click away!</h2>
          
          <img src={Testimonial} alt="Testimonial" className="w-full h-auto object-cover rounded-lg mb-8"/>
          
          {/* Social Proof Metrics */}
          <div className="grid grid-cols-3 gap-4 text-white mb-8">
            <div className="text-center">
              <UserIcon className="h-8 w-8 mx-auto mb-2" />
              <p className="text-3xl font-bold">1000+</p>
              <p className="text-sm">Students Helped</p>
            </div>
            <div className="text-center">
              <LightningBoltIcon className="h-8 w-8 mx-auto mb-2" />
              <p className="text-3xl font-bold">250k+</p>
              <p className="text-sm">Questions Answered</p>
            </div>
            <div className="text-center">
              <AcademicCapIcon className="h-8 w-8 mx-auto mb-2" />
              <p className="text-3xl font-bold">95%</p>
              <p className="text-sm">Average Grade</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
